@import "_index.less";

main.main {
	>section.main-section {
        >.section-center {
        	>section.section-banner {
			    //background-color: #000;
			    //background-image: url("@{host}/page/index/public/img/banner.png");
			    //background-size: cover;
			    //background-position: center center;	
			    
        	}
        	>section.section-about {
        		background-color: #fff;
        		padding: 4rem 0;
        		box-shadow: inset 0px 20px 30px -30px #000;
        		.section-header {
        			>p {
        				text-align: center;
					    color: @colorSecondary;
					    text-transform: uppercase;
					    padding: 0;
					    margin: 0;
        			}
        			>h1 {
        				text-align: center;
					    color: @colorSecondary;
					    margin: 0 0 2rem 0;
        			}
        		}
        		.section-content {
        			.card {
						border: none;
					    border-radius: 0;
					    background-color: transparent;
					    padding: 2rem;
        				>.card-image {
        					text-align: center;
        					padding: 1rem 0;
        					>i {
        						font-size: 4rem;
        						color: #a9a9a9;
        					}
        				}
        				>.card-body {
	        				>h3.card-title {
	        					text-align: center;
	        					text-transform: uppercase;
	        					nt-size: 1.2rem;	
	        					color: @colorSecondary;
	        				}
	        				>p.card-text {
							    text-align: center;
							    color: #838383;
							    line-height: 16px;
							    font-size: .8rem;
	        				}
        				}
        				>.card-footer {
						    background-color: transparent;
						    border: none;
						    text-align: center;
		        			.btn {
		        				border-radius: 0;
		        				border: none;
		        				background-color: @colorPrimary;
		        				color: #fff;
		        				padding: .5rem 1rem;
		        				.transition();
		        				&:hover {
			        				background-color: #fff;
			        				color:  @colorPrimary;
		        				}
		        			}
        				}
        			}
					.row .col-4:nth-child(2) >.card {
					    border-left: 1px solid #ccc;
					    border-right: 1px solid #ccc;
					}
        		}
        	}
        	>section.section-vehicle {
        		background-color: @colorSecondary;
        		padding: 4rem 0;
        		.section-header {
        			>p {
					    color: #fff;
					    text-transform: uppercase;
					    padding: 0;
					    margin: 0;
        			}
        			>h2 {
        				color: #fff;
        				font-size: 2rem;
					    padding: 0;
					    margin: 0;
        			}
        		}	
        		.section-content {
				    padding: 4rem 0;
			        .vehicle-gallery-container {
			            position: relative;
			            overflow: hidden;
			            white-space: nowrap;
			            width: 100%;
				        .vehicle-gallery-content {
							width: 100%;
						    overflow: hidden;
					        .content-image {
					            display: inline-block;
					            width: 350px;
					            margin: 0 5px;
					            background-color: #fff;
					            >a.card {
								    background-color:#fff;
					            	border: none;
								    border-radius: 0;
					            	width: 350px;
						            >.card-img {
						            	position: relative;
									    background-size: auto 100%;
									    background-position: center;
									    height: 250px;
					            		width: 350px;
					            		border-radius: 0;
				            			>.card-img-sold {
				                            position: absolute;
				                            top: 0;
				                            left: 0;
				                            right: 0;
				                            bottom: 0;
				                            background-color: rgba(255, 0, 0, 0.25);   
				                            pointer-events: none;
				                            >span {
				                                color: #fff;
				                                font-weight: bold;
				                                margin: 30% 0 0;
				                                display: block;
				                                font-size: 36px;
				                                text-align: center;
				                                transform: rotate(-20deg);
				                                text-shadow: 1px 1px 4px rgba(0, 0, 0, 0.25), -1px -1px 4px rgba(0, 0, 0, 0.25), -1px 1px 4px rgba(0, 0, 0, 0.25), 1px -1px 4px rgba(0, 0, 0, 0.25);
				                            }
				            			}
				            			>.card-img-icon {
				    					    background-color: #fff;
				    					    color: @colorPrimary;
				    					    float: right;
				    					    margin: 0 5px 0 0;
				    					    padding: 5px;
				    					    border-radius: 0 0 5px 5px;
				    					    opacity: 0;
				            				.transition();
				            				&.icon-favorite {
				            					
				            				}
				            				&.icon-instock {
				            					
				            				}
				            				&.icon-blowheart {
				            					
				            				}
				            			}
				            			>.card-img-number {
				    					    position: absolute;
				    					    right: 0;
				    					    bottom: 0;
				    					    background-color: @colorPrimary;
				    					    color: #fff;
				    					    padding: 5px 10px;
				    					    margin: 5px;
				    					    border-radius: 0.25rem;
				    					    opacity: 0;
				            				.transition();
				            				>span {
				            					color: #fff;
				    	        				>i {
				    	        					color: #fff;
				    	        				}
				            				}
				            			}
									    >.card-price {
									        position: absolute;
										    top: 5px;
										    left: 5px;
										    background-color: #3d3d3d;
										    color: #fff;
										    padding: .25rem 1rem;
										    font-size: 1.2rem;
										    font-weight: 600;
										    border-radius: .5rem;
										    .transition();
									    }
						            }
						            >.card-body {
						            	padding: 0;
						            	margin: 0;
							            >h5.card-title {
							            	color: @colorSecondary;
							            	text-align: left;
							            	font-size: .9rem;
							            	padding: 1rem;
							            	border-bottom: 1px solid #ccc;
										    white-space: nowrap;
										    overflow: hidden;
										    text-overflow: ellipsis;
										    width: 350px;
							            	>b {
							            		font-size: 1.2rem;
							            	}
							            	.transition();
							            }
							            >ul.card-data {
							            	list-style: none;
							            	margin: 0;
							            	padding: 0;	
							            	>li {
											    width: 50%;
											    float: left;
											    padding: 1rem .5rem;
											    color: @colorSecondary;
											    text-align: center;
											    font-size: 1rem;
											    font-weight: 500;
											    opacity: .7;
											    .transition();
							            	}
							            }
						            }
					            }
					            &:hover {
					            	>a.card {
					            		background-color: @colorPrimary;
						            	>.card-img {
									    	>.card-price {
										    	background-color: @colorPrimary;
											    top: 15px;
											    left: 15px;
									    	}						            		
						            	}
						            	>.card-body {
							            	>h5.card-title {
							            		color: #fff;
							            		border-bottom: 1px solid #ccc;
							            	}
							            	>ul.card-data {
							            		>li {
							            			color: #fff;
							            		}
							            	}
						            	}
					            	}
					            }
				        	}
				        }
				        .vehicle-gallery-button {
				            position: absolute;
				            top: 50%;
				            transform: translateY(-50%);
				            background-color: rgba(0, 0, 0, 0.5);
				            color: white;
				            border: none;
				            cursor: pointer;
				            padding: 10px;
					        &.left {
					            left: 10px;
					        }
					        &.right {
					            right: 10px;
					        }
				        }
			        }
		        
        		}	
        		.section-footer {
        			text-align: center;
        			.btn {
        				border-radius: 0;
        				border: none;
        				background-color: @colorPrimary;
        				color: #fff;
        				padding: .5rem 1rem;
        				.transition();
        				&:hover {
	        				background-color: #fff;
	        				color:  @colorPrimary;
        				}
        			}
        		}	
        	}  
        	>section.section-service {
        		background-color: #f1f5fa;
        		padding: 4rem 0;
        		.section-header {
        			margin-bottom: 2rem;
        			>p {
					    color: @colorSecondary;
					    text-transform: uppercase;
					    padding: 0;
					    margin: 0;
					    font-size: 0.8rem;
					    font-weight: 500;
					    opacity: .7;
					    text-align: center;
        			}
        			>h2 {
        				color:@colorSecondary;
        				font-size: 2rem;
					    padding: 0;
					    margin: 0;
					    text-align: center;
					    text-transform: uppercase;
        			}
        		}
        		.section-content {
	        		.list-cards {
	        			margin-bottom: 2rem;
	        			.card {
						    border: none;
						    border-radius: 0;
						    padding: 0;
	        				>.card-image {
							    font-size: 4rem;
							    text-align: center;
							    color: @colorPrimary;
	        				}
	        				>.card-body {
	        					padding: 0 .5rem 1rem .5rem;
							    >p {
							    	margin: 0;
								    text-align: center;
								    font-size: .9rem;
								    opacity: .9;
								    text-transform: uppercase;
								    font-weight: 600;
							    }
	        				}
	        			}
	        		}
        			.card-thumbnail {
		        		height: 350px;
					    background-color: #000;
					    background-image: url("@{host}/page/index/public/img/service-thumbnail.jpeg");
					    background-size: cover;
					    background-position: center center;	
        			}
        			h3 {
        				    text-transform: capitalize;
        			}
        			p {	    
        				text-align: justify;
					    font-size: .9rem;
					    opacity: .9;
        			}
        		}
        	}        	
        }
    }
}

@media screen and (max-width: 768px) {
	main.main {
		>section.main-section {
            section.section-vehicle {
			    .carousel .carousel-inner .carousel-item-right.active,
			    .carousel .carousel-inner .carousel-item-next {
			    	transform: translateX(16.666%);
			    }
			    .carousel .carousel-inner .carousel-item-left.active, 
			    .carousel .carousel-inner .carousel-item-prev {
			    	transform: translateX(-16.666%);
			    }
            }
        }
	}	
}